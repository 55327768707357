import React, { useState, useEffect } from "react";

function PlayerPods() {
  const [players, setPlayers] = useState(() => {
    return JSON.parse(localStorage.getItem("players")) || [];
  });
  const [name, setName] = useState("");
  const [message, setMessage] = useState(null);
  const [pods, setPods] = useState([]);
  const [minPodSize, setMinPodSize] = useState(3);
  const [maxPodSize, setMaxPodSize] = useState(4);
  const [eliminatedPlayers, setEliminatedPlayers] = useState({});
  const [leaderboard, setLeaderboard] = useState(() => {
    return JSON.parse(localStorage.getItem("leaderboard")) || {};
  });

  useEffect(() => {
    localStorage.setItem("players", JSON.stringify(players));
  }, [players]);

  useEffect(() => {
    localStorage.setItem("leaderboard", JSON.stringify(leaderboard));
  }, [leaderboard]);

  useEffect(() => {
    localStorage.setItem("pods", JSON.stringify(pods));
  }, [pods]);

  const handleAddPlayer = () => {
    if (name.trim() === "") return;
    setPlayers((prevPlayers) => {
      const updatedPlayers = [...prevPlayers, name];
      localStorage.setItem("players", JSON.stringify(updatedPlayers));
      return updatedPlayers;
    });
    setName("");
  };

  const handleRemovePlayer = (index) => {
    setPlayers((prevPlayers) => {
      const updatedPlayers = prevPlayers.filter((_, i) => i !== index);
      localStorage.setItem("players", JSON.stringify(updatedPlayers));
      return updatedPlayers;
    });
  };

  const handleEliminatePlayer = (podIndex, player) => (event) => {
    const eliminator = event.target.value;
    setEliminatedPlayers((prev) => ({ ...prev, [player]: eliminator }));
    if (eliminator !== "scoop" && eliminator) {
      setLeaderboard((prev) => ({
        ...prev,
        [eliminator]: (prev[eliminator] || 0) + 1,
      }));
    }
  };

  const generatePods = () => {
    setMessage(null);
    let activePlayers = players.filter(player => !eliminatedPlayers[player]);
  
    if (activePlayers.length < 3) {
      setMessage("At least 3 active players are required to generate pods.");
      return;
    }
  
    // Shuffle players randomly
    activePlayers = [...activePlayers].sort(() => Math.random() - 0.5);
    const newPods = [];
  
    while (activePlayers.length >= 3) {
      let podSize = 3; // Default pod size is 3
  
      // If exactly 4 players are left, make a single pod of 4
      if (activePlayers.length === 4) {
        podSize = 4;
      } 
      // If 5 players remain, create a pod of 3 first, leaving 2 for a future loop iteration
      else if (activePlayers.length === 5) {
        podSize = 4;
      } 
      // If 6 players remain, create two pods of 3
      else if (activePlayers.length === 6) {
        podSize = 3;
      } 
      // If 7 players remain, create a pod of 4, leaving 3 for the next iteration
      else if (activePlayers.length === 7) {
        podSize = 4;
      } 
      // If 8 players remain, create two pods of 4
      else if (activePlayers.length === 8) {
        podSize = 4;
      }
  
      newPods.push(activePlayers.splice(0, podSize));
    }
  
    setPods(newPods);
  };
  

  const endGame = () => {
    setEliminatedPlayers({});
    setPods([]);
    setMessage("Game reset. All players are back in the active pool.");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-gray-700">Create Players & Generate Pods</h2>
        {message && <p className="text-center text-red-500 mt-2">{message}</p>}
        
        <div className="mt-4">
          <label className="block text-gray-700">Player Name</label>
          <input
            type="text"
            className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="Enter player name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <button
            onClick={handleAddPlayer}
            className="w-full px-4 py-2 mt-4 font-bold text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            Add Player
          </button>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-bold text-gray-700">Registered Players</h3>
          <ul className="mt-2 bg-gray-50 p-3 rounded-lg">
            {players.map((player, index) => (
              <li key={index} className="p-2 border-b last:border-none flex justify-between">
                {player}
                <button 
                  onClick={() => handleRemovePlayer(index)}
                  className="px-2 py-1 text-sm text-white bg-red-600 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        <button
          onClick={generatePods}
          className="w-full px-4 py-2 mt-4 font-bold text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400"
        >
          Generate Pods
        </button>

        <button
          onClick={endGame}
          className="w-full px-4 py-2 mt-4 font-bold text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400"
        >
          End Game
        </button>

        <div className="mt-6">
          <h3 className="text-lg font-bold text-gray-700">Generated Pods</h3>
          {pods.length > 0 ? (
            pods.map((pod, podIndex) => (
              <div key={podIndex} className="p-4 mt-2 bg-gray-200 rounded">
                <h4 className="font-bold">Pod {podIndex + 1}</h4>
                <ul>
                  {pod.map((player, i) => (
                    <li key={i}>{player}</li>
                  ))}
                </ul>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No pods generated yet.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default PlayerPods;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiRequest } from "../services/api";

function Dashboard() {
    const [leagues, setLeagues] = useState([]);
    const [unvents, setUnvents] = useState([]);
    const token = localStorage.getItem("token");

    useEffect(() => {
        try
        {
            apiRequest('GET', "league").then(response => {
                setLeagues(response);
            })
        }
        catch (error)
        {
            console.error("Error fetching leagues:", error);
            setLeagues([]); // Prevent UI from breaking
        }
    }, [token]);

    return (
        <div className="flex items-center justify-center mt-3 p-10 bg-gray-100">
            <div className="min-h-screen bg-gray-100 p-6">
                <h2 className="text-3xl font-bold text-gray-700 text-center mb-6">Dashboard</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Leagues Card */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h3 className="text-xl font-bold text-gray-700">Leagues</h3>
                        <Link to="/dashboard/new-league">Create New League</Link>
                        <ul className="mt-4">
                            {leagues.length > 0 ? leagues.map((league) => (
                                <li key={league.id} className="p-2 border-b">{league.name}</li>
                            )) : <p className="text-gray-500">No leagues available</p>}
                        </ul>
                    </div>

                    {/* Upcoming Events Card */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h3 className="text-xl font-bold text-gray-700">Upcoming Events</h3>
                        <ul className="mt-4">
                            {unvents.length > 0 ? unvents.map((event) => (
                                <li key={unvents.id} className="p-2 border-b">{unvents.name} - {unvents.date}</li>
                            )) : <p className="text-gray-500">No upcoming events</p>}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
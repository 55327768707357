import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import Navbar from "./components/Navbar";
import NewLeague from "./pages/League";
import PlayerPods from "./podman/pod";

function App() {
  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<PlayerPods />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
        <Route path="/dashboard/new-league" element={<ProtectedRoute component={NewLeague} />} />

        <Route path="/dashboard/player" element={<ProtectedRoute component={NewLeague} />} />
        <Route path="/dashboard/player/create" element={<ProtectedRoute component={NewLeague} />} />

        <Route path="/dashboard/event" element={<ProtectedRoute component={NewLeague} />} />
        <Route path="/dashboard/event/create" element={<ProtectedRoute component={NewLeague} />} />
        <Route path="/dashboard/event/add-player" element={<ProtectedRoute component={NewLeague} />} />

        <Route path="/dashboard/pods/create" element={<ProtectedRoute component={NewLeague} />} />
      </Routes>
    </Router>
  );
}

export default App;

import axios from "axios";

export async function apiRequest(method, endpoint, body = null) {
    const API_URL = "http://localhost:8000"
    const token = localStorage.getItem("token");
    try {
        const response = await axios({
            method: method,
            url: `${API_URL}/${endpoint}`,
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            data: body
        });

        return response.data;
    } catch (err) {
        throw new Error(err);
    }
}
import React, { useState, useEffect } from "react";
import { apiRequest } from "../services/api";

function NewLeague() {
    const [name, setName] = useState("");
    const [message, setMessage] = useState(null);
  
    const handleCreateLeague = async () => {
      try {
        const token = localStorage.getItem("token");
        await apiRequest('POST', "league", { name }, token)
        setMessage("League created successfully!");
      } catch (err) {
        setMessage("Failed to create league");
      }
    };
  
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-center text-gray-700">Create New League</h2>
          {message && <p className="text-center text-blue-500">{message}</p>}
          <div className="mt-4">
            <label className="block text-gray-700">League Name</label>
            <input
              type="text"
              className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter league name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <button
            onClick={handleCreateLeague}
            className="w-full px-4 py-2 mt-4 font-bold text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400">
            Create League
          </button>
        </div>
      </div>
    );
  }
  
  export default NewLeague;